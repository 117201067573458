




import { Component, Vue } from "vue-property-decorator";
import Documentacion from "@/components/Documentacion/Documentacion.vue";
@Component({
  components: {
    Documentacion
  },
  meta: {
    // sets document title
    title: "Documentos - Municipalidad de Juan Fernandez",
    // optional; sets final title as "Index Page - My Website", useful for multiple level meta
    //titleTemplate: title => `${title} - My Website`,

    // meta tags
    meta: {
      description: {
        name: "documentos",
        content: "Documentos."
      },
      keywords: { name: "keywords", content: "Municipalidad de Juan Fernandez" },
      equiv: {
        "http-equiv": "Content-Type",
        content: "text/html; charset=UTF-8"
      }
    }
  }
})
export default class DocumentacionView extends Vue {
  private mounted() {
    document.title = "Documentos - Municipalidad de Juan Fernandez";
  }
}
