




















































































import { Component, Vue, Watch } from "vue-property-decorator";

@Component({})
export default class Documentacion extends Vue {
  private nombreWeb: any = "";
  private documentos: any = [];
  private currentPage = 1;
  private total = 0;
  private mostrar = false;
  private subtitulo = "";

  @Watch("currentPage")
  private currentPageWatch(newValue: any) {
    if (newValue >= 0 && newValue <= Math.ceil(this.total / 12)) {
      this.getDocumentacion();
    }
  }

  private mounted() {
    if (
      this.$router.currentRoute.params.nombre &&
      this.$router.currentRoute.params.nombre != ""
    ) {
      this.nombreWeb = this.$router.currentRoute.params.nombre;
      // console.log(this.nombreWeb);
      this.subtitulo = this.nombreWeb.replace(/-/g, " ");
      this.subtitulo =
        this.subtitulo.charAt(0).toUpperCase() + this.subtitulo.slice(1);

      this.getDocumentacion();
    }
  }

  private getDocumentacion() {
    this.$q.loading.show({
      delay: 100, // ms
    });
    this.$axios
      .get(
        "documentos/categoria?pagina=" +
          this.currentPage +
          "&nombre_web=" +
          this.nombreWeb
      )
      .then((res: any) => {
        // console.log(res.data.documentos);
        if (res.data.documentos.length == 0) {
          this.mostrar = true;
        }
        // console.log("docu", res.data);
        this.documentos = res.data.documentos;
        this.total = res.data.total;
        this.$q.loading.hide();
      })
      .catch((err: any) => {
        this.mostrar = true;
        this.$q.loading.hide();
        console.log(err);
      });
  }

  private toLink(item: any, target: any) {
    if (item && item != "") {
      window.open("https://docs.munijf.cl/" + item, target);
    } else {
      return;
    }
  }
}
